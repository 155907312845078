/*
$( document ).ready(function() {
  $(document).on('collapsed.lte.pushmenu', handleCollapsedEvent)
  $(document).on('shown.lte.pushmenu', handleExpandedEvent)
  function handleCollapsedEvent() {
    if ($('.sidebar-closed').length) {
      setNavLink('0', '0')
    }
    $('.sidebar').hover(function(){
      $('[data-widget="pushmenu"]').PushMenu('expand');
    });
    $('.sidebar-collapse .main-sidebar .sidebar .nav-pills span').hide();
    if ($(window).width() < 900) {
      if ($('.sidebar-closed').length) {
        setNavLink('50px', '45px');
          $('.sidebar-collapse .main-sidebar').css({"margin-left": "0", "width":"3.8rem"} );
          $('.sidebar-collapse .main-sidebar .sidebar .nav-link p').hide();
          $('.sidebar-collapse .main-sidebar .sidebar .nav-pills span').hide();
          $('.sidebar-collapse .main-sidebar .sidebar span.sidebar-mini').hide();
          $('.brand-image-xl.logo-xs.logo-show-mobile').show();
          $('.brand-image-xs.logo-xl.logo-hide-mobile').hide();
          $('.sidebar-collapse .main-footer').css({"margin-left": "68px", "width" : "unset"} );

        }
        if ($(window).width() < 426) {
          $('.sidebar-collapse .content-wrapper .content.content-width').css("width","unset" );
        }
      }
  }

  function handleExpandedEvent() {
    $('.main-sidebar .sidebar .nav-pills span').show();
    if ($('.sidebar-open').length) {
      setNavLink('250px', '250px');
      if ($(window).width() < 900) {
        $('.sidebar-open .main-sidebar').css({"margin-left": "0", "width":"250px"} );
          $('.sidebar-open .main-sidebar .sidebar .nav-link p').show();
          $('.sidebar-open .main-sidebar .sidebar span.sidebar-mini').show();
          $('.brand-image-xs.logo-xl.logo-hide-mobile').show();
          $('.brand-image-xl.logo-xs.logo-show-mobile').hide();
          $('.sidebar-open .main-sidebar .sidebar .nav-pills span').show();
          $('.sidebar-open .main-footer').css({"margin-left": "250px", "width" : "400px"} );
          $('html , body').css("overflow" , "hidden");
        }
      if ($(window).width() < 426) {
        $('.sidebar-open .content-wrapper .content.content-width').css("width","400px" );
      }
    }


    if (window.innerWidth > window.innerHeight) {
      if ($(window).width() > 993) {
        $('.content-wrapper').css('margin-left', '250px');
        $('.navbar .nav-link').css('margin-left', '0');
      }
    }
  }

  function setNavLink(wrapper_margin, link_margin) {
    $('.content-wrapper').css('margin-left', wrapper_margin);
    $('.navbar .nav-link').css('margin-left', link_margin);
  }
});
*/

$(window).resize(autoCollapseSideBar);

function autoCollapseSideBar() {
  $("body").addClass('sidebar-collapse');
}
