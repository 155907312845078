// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/webpacker and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
//require("../../../.rvm/gems/ruby-2.6.3/gems/turbolinks-source-5.2.0/lib/assets/javascripts/turbolinks").start();
require("@rails/activestorage").start();

var jQuery = require("jquery");
require('jquery-ui');
require("parsleyjs");
require("packs/sidebar");
// require("packs/jquery-ui");
// import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require("bootstrap/dist/js/bootstrap");
require("admin-lte/dist/js/adminlte");
require("packs/phone_validator");

import '../../assets/stylesheets/application'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

// FontAwesome
import '@fortawesome/fontawesome-free/js/all'

import "controllers"

//import { Application } from "stimulus"
//import { definitionsFromContext } from "stimulus/webpack-helpers"

//const application = Application.start()
//const context = require.context("../src", true, /\.js$/)
//application.load(definitionsFromContext(context))
