$( document ).on('ready turbolinks:load', function() {
  $('body').on('keyup', '#phone, #enc_contact_number, #rp-phone, .ins-phone ', function(e){
    phone_validator(e)
  })
});

function phone_validator(e) {
  phone = $(e.target).val().split('+1')
  e.target.value = phone.length > 1 ? phone[1] : phone[0]
  var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
}
